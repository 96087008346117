import { Validator } from 'formstate';

export const required = (val: any) => !val && 'Skal udfyldes';

export const isEmail: Validator<any> = (value: string) => {
    // Empty values are not invalid emails
    if (!value) return null;
    if (required(value)) return null;
    value = value.trim();
    // Src : http://emailregex.com/
    //eslint-disable-next-line
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(value)) {
        return 'Ugyldig email'
    }
    return null;
}