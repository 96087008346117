import * as React from 'react';
import { observer } from 'mobx-react';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import SelectAudience from 'components/SelectAudience';
import SelectQuestion from 'components/SelectQuestion';
import SelectPushType from 'components/SelectPushType';
import SelectNotificationIcon from 'components/SelectNotificationIcon';
import Input from 'components/Forms/Input';
import { Button } from 'rsuite';
import { navigationStore } from 'stores/NavigationStore';
import DateInput from 'components/Forms/DateInput';
import { loadingStore } from 'stores/LoadingStore';

export interface NotificationFormDataInterface {
    title?: any,
    message?: any,
    url?: any,
    audienceId?: any,
    questionId?: any,
    notification_icon_type?: any,
    scheduleTime?: any,
    id?: any,
    pushType?: any
}

interface Props {
    onSubmit: (data: NotificationFormDataInterface) => void,
    editData?: NotificationFormDataInterface,
    hideSaveButton?: boolean,
    onDelete?: () => void
}

@observer class NotificationForm extends React.Component<Props> {

    form = new FormState({
        title: new FieldState('').validators(required).disableAutoValidation(),
        message: new FieldState('').validators(required).disableAutoValidation(),
        url: new FieldState(''),
        audienceId: new FieldState('').validators(required).disableAutoValidation(),
        questionId: new FieldState(''),
        notification_icon_type: new FieldState(''),        
        scheduleTime: new FieldState(''),
        pushType: new FieldState(''),
    })

    constructor(props: Props) {
        super(props);

        if (props.editData) {
            const form = this.form.$;
            const data = props.editData;

            form.title.reset(data.title);
            form.message.reset(data.message);
            form.url.reset(data.url);
            form.audienceId.reset(data.audienceId);
            form.questionId.reset(data.questionId);
            form.notification_icon_type.reset(data.notification_icon_type);
            form.scheduleTime.reset(data.scheduleTime);
            form.pushType.reset(data.pushType);
        }
    }

    onSubmit = () => {
        const { onSubmit, editData } = this.props;
        const form = this.form.$;

        const data = {
            id: editData?.id ?? null,
            title: form.title.$,
            message: form.message.$,
            url: form.url.$,
            scheduleTime: form.scheduleTime.$ || new Date(),
            audienceId: form.audienceId.$,
            pushType: form.pushType.$ || false,
            questionId: form.questionId.$,
            notification_icon_type: form.notification_icon_type.$
        } as NotificationFormDataInterface;

        onSubmit(data);
    }

    render() {
        const { hideSaveButton, onDelete } = this.props;
        const form = this.form.$;
        const isLoading = loadingStore.isLoading;

        return (
            <Form onValidSubmit={this.onSubmit} formState={this.form}>
                <SelectAudience
                    disabled={hideSaveButton}
                    fieldState={form.audienceId} />

                <SelectQuestion
                    disabled={hideSaveButton}
                    fieldState={form.questionId} />

                <Input
                    disabled={hideSaveButton}
                    label='Overskrift (*påkrævet)'
                    fieldState={form.title}
                />

                <Input
                    disabled={hideSaveButton}
                    label='Besked (*påkrævet)'
                    type='textarea'
                    fieldState={form.message}
                />

                <Input
                    disabled={hideSaveButton}
                    label='URL til deeplink (valgfrit)'
                    fieldState={form.url}
                />
                
                <SelectNotificationIcon
                    disabled={hideSaveButton}
                    fieldState={form.notification_icon_type} />

                <DateInput
                    disabled={hideSaveButton}
                    label='Dato (*påkrævet)'
                    placeholder='Nu (hurtigst muligt)'
                    fieldState={form.scheduleTime}
                />

                 <SelectPushType
                    disabled={hideSaveButton}
                    fieldState={form.pushType} />

                <div style={{ textAlign: 'center', margin: '80px 0' }}>
                    {
                        !hideSaveButton &&
                        <Button color='red' type='submit' loading={isLoading}>Gem Notifikation</Button>
                    }

                    <Button
                        style={!hideSaveButton ? { marginLeft: '20px' } : undefined}
                        onClick={() => navigationStore.push('/')}>
                        Tilbage
                    </Button>
                </div>

                {
                    (onDelete && !hideSaveButton) &&
                    <div style={{ textAlign: 'center', marginBottom: '60px' }}>
                        <Button 
                            appearance='link' 
                            style={{color: 'grey'}}
                            onClick={onDelete}>Slet notification</Button>
                    </div>
                }
            </Form>
        )
    }
}

export default NotificationForm;