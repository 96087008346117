import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
// import { audienceStore } from 'stores/AudienceStore';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>,
    disabled?: boolean
}

@observer class SelectPushType extends React.Component<Props> {

    componentDidMount() {
        // audienceStore.getAudiences();
    }

    render() {
        const { fieldState, disabled } = this.props;
        const data = [{"name": "Med push", "value" : false}, 
                      {"name": "Uden push", "value" : true}];

        const options = data.map((d) => ({
            value: d.value, 
            label: d.name
        }))

        return (
            <Dropdown
                disabled={disabled}
                label='Send Push Besked'
                fieldState={fieldState}
                options={_.orderBy(options, 'label')}
            />
        )
    }
}

export default SelectPushType;