import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { required, isEmail } from 'helpers/FormValidation';
import { navigationStore } from 'stores/NavigationStore';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import { Parse } from 'helpers/Parse';
import { authStore } from 'stores/AuthStore';
import { Button } from 'rsuite';
import CenterContainer from 'components/CenterContainer';
import { loadingStore } from 'stores/LoadingStore';

@observer class LoginPage extends React.Component {

    form = new FormState({
        email: new FieldState('').validators(required).disableAutoValidation(),
        password: new FieldState('').validators(required).disableAutoValidation()
    })

    constructor(props: any) {
        super(props);

        if (Parse.User.current()) {
            navigationStore.replace('/');
        }
    }

    onSubmit = () => {
        const form = this.form.$;
        authStore.login(form.email.$, form.password.$);
        form.password.reset();
    }

    render() {
        const form = this.form.$;
        const isLoading = loadingStore.isLoading;

        return (
            <CenterContainer showLogo={true}>
                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                    <Input
                        label='Telefonnummer'
                        fieldState={form.email}
                        placeholder='ex 4543301404'
                    />

                    <Input
                        label='Pinkode (4-cifre)'
                        type='password'
                        fieldState={form.password}
                        placeholder='Pin-kode'
                    />

                    <div style={{ textAlign: 'right', marginTop: '20px' }}>
                        <Button
                            color='red'
                            type='submit'
                            loading={isLoading}>
                            Log ind
                    </Button>
                    </div>

                </Form>
            </CenterContainer>
        )
    }
}

export default LoginPage;