import { observable, action } from "mobx";
import { Parse } from 'helpers/Parse';
import LoadPromise from "helpers/LoadPromise";
import { navigationStore } from 'stores/NavigationStore';
import _ from 'lodash';
import { NotificationFormDataInterface } from "components/NotificationForm";
import { replaceObjectById, removeObjectById } from "helpers/CollectionHelper";

export class NotificationStore {

    @observable notifications: Array<Parse.Object> = [];

    @action
    getAll = (limit: number = 9999) => {
        return LoadPromise(async (resolve, reject) => {

            if (!Parse.User.current()) {
                navigationStore.replace('/login');
                resolve();
                return;
            }
            const query = new Parse.Query('NotificationQueue');
            query.descending('schedule_time');

            query.limit(limit);

            const res = await query.find();
            this.notifications = res;
            resolve();
        })
    }

    @action
    get = (id: string) => {
        return LoadPromise(async (resolve, reject) => {
            const existingNotification = _.find(this.notifications, { id: id });
            if (existingNotification) {
                resolve(existingNotification);
            }
            else {
                const query = new Parse.Query('NotificationQueue');
                const res = await query.get(id);
                if (res) {
                    this.notifications.push(res);
                    resolve(res);
                }
            }
        })
    }

    @action
    create = (data: NotificationFormDataInterface) => {
        return LoadPromise(async (resolve, reject) => {
            const NotificationQueue = Parse.Object.extend('NotificationQueue');
            const notificationQueue = new NotificationQueue();

            this._mapToParse(notificationQueue, data);

            const savedObj = await notificationQueue.save();
            this.notifications.unshift(savedObj);
            resolve(savedObj);
        })
    }

    _mapToParse = (notificationQueue: Parse.Object, data: NotificationFormDataInterface) => {
        notificationQueue.set('title', data.title);
        notificationQueue.set('message', data.message);
        notificationQueue.set('url', data.url);
        notificationQueue.set('audienceId', data.audienceId);
        notificationQueue.set('questionId', data.questionId);
        notificationQueue.set('notification_icon_type', data.notification_icon_type);
        notificationQueue.set('schedule_time', data.scheduleTime);
        notificationQueue.set('no_push', ((data.pushType === "true") ? true : false));
        return notificationQueue;
    }

    @action
    update = (data: NotificationFormDataInterface) => {
        return LoadPromise(async (resolve, reject) => {
            const query = new Parse.Query('NotificationQueue');
            const notificationQueue = await query.get(data.id);

            this._mapToParse(notificationQueue, data);

            const savedObj = await notificationQueue.save();
            this.notifications = replaceObjectById(this.notifications, savedObj);
            resolve(savedObj);
        })
    }

    @action
    delete = (id: string) => {
        return LoadPromise(async (resolve, reject) => {
            const query = new Parse.Query('NotificationQueue');
            const res = await query.get(id);
            if (res) {
                await res.destroy();
                this.notifications = removeObjectById(this.notifications, res);
                resolve();
            }
            else reject();
        })
    }
}

export const notificationStore = new NotificationStore();