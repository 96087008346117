import * as React from 'react';
import { observer } from 'mobx-react';
import Flatpickr from 'react-flatpickr';
import { FieldState } from 'formstate';
import moment from 'moment';
import 'flatpickr/dist/themes/light.css';

import './style.css';
import FormError from '../Form/FormError';
import Label from '../Form/Label';

interface Props {
    fieldState: FieldState<any>,
    placeholder?: string,
    label?: string,
    disabled?: boolean
}

@observer class DateInput extends React.Component<Props> {

    onChange = (value: any) => {
        const { fieldState } = this.props;
        const val = value[0];
        console.log(val)
        fieldState.onChange(val);
    }

    render() {
        const { fieldState, placeholder, label, disabled } = this.props;
        let value = fieldState.value ? moment(fieldState.value).toISOString() : undefined;

        return (
            <div>
                {
                    label &&
                    <Label label={label} errorMessage={fieldState.error} />
                }

                <Flatpickr
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={this.onChange}
                    options={{
                        enableTime: true,
                        time_24hr: true,
                        disable: [
                            (selectedDate) => {
                                var today = new Date();
                                today.setHours(0, 0, 0, 0);
                                return (selectedDate < today);
                            }
                        ],
                        locale: {
                            firstDayOfWeek: 1,
                            weekdays: {
                                shorthand: ['søn', 'man', 'tirs', 'ons', 'tors', 'fre', 'lør'],
                                longhand: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag']
                            },
                            months: {
                                shorthand: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                                longhand: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
                            }
                        },
                        dateFormat: 'd/m/Y H:i'
                    }}
                    value={value} />

            </div>
        )
    }
}

export default DateInput;