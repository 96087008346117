import { navigationStore } from 'stores/NavigationStore';
import { Parse } from 'helpers/Parse';

const NoMatchPage = () => {
    if (Parse.User.current()) {
        navigationStore.replace('/');
    }
    else {
        navigationStore.replace('/login');
    }

    return null;
}

export default NoMatchPage;