import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'normalize.css';
import 'rsuite/dist/styles/rsuite-default.css';
import 'styles/global.css';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
