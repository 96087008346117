import { loadingStore } from 'stores/LoadingStore';
import { toastStore } from 'stores/ToastStore';

async function LoadPromise(callback: (s: any, reject: any) => Promise<unknown>): Promise<unknown> {
    loadingStore.addRequest();
    const promise = new Promise(callback)
        .catch((err) => {
            toastStore.addError(err);
        })
        .finally(() => {
            loadingStore.removeRequest();
        })
    return promise;
}

export default LoadPromise;