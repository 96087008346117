import * as React from 'react';
import { observer } from 'mobx-react';
import FormError from '../FormError';

interface Props {
    label: string,
    errorMessage?: string
}

@observer class Label extends React.Component<Props> {

    render() {
        const { label, errorMessage } = this.props;

        return (
            <div style={{ marginTop: '20px', paddingBottom: '4px' }}>
                <label htmlFor="">{label}</label>
                <FormError errorMessage={errorMessage} />
            </div>
        )
    }
}

export default Label;