import * as React from 'react';
import { observer } from 'mobx-react';
import CenterContainer from 'components/CenterContainer';
import NotificationForm from 'components/NotificationForm';
import { notificationStore } from 'stores/NotificationStore';
import _ from 'lodash';
import { NotificationFormDataInterface } from 'components/NotificationForm';
import { navigationStore } from 'stores/NavigationStore';

@observer class EditNotificationPage extends React.Component<any> {

    componentDidMount() {
        notificationStore.get(this.getNotificationIdByParams());
    }

    getNotificationIdByParams() {
        return this.props.match.params.id;
    }

    onDelete = async () => {
        if (window.confirm('Er du sikker på at du vil slette denne notifikation?')) {
            await notificationStore.delete(this.getNotificationIdByParams());
            navigationStore.replace('/');
        };
    }

    onSubmit = async (data: NotificationFormDataInterface) => {
        await notificationStore.update(data);
        navigationStore.push('/');
    }

    parseNotificationToEditData(notification: Parse.Object): NotificationFormDataInterface | undefined {
        if (!notification) return undefined;

        return {
            id: notification.id,
            audienceId: notification.get('audienceId'),
            questionId: notification.get('questionId'),
            notification_icon_type: notification.get('notification_icon_type'),
            message: notification.get('message'),
            scheduleTime: notification.get('schedule_time'),
            title: notification.get('title'),
            url: notification.get('url'),
            pushType: notification.get('no_push')
        }
    }

    render() {
        const data = notificationStore.notifications;
        const notification = _.find(data, { id: this.getNotificationIdByParams() });
        if (!notification) return null;
        const canSave = !notification.get('is_sent');

        return (
            <CenterContainer title={canSave ? 'Rediger Notifikation' : 'Vis Notifikation'}>
                <NotificationForm
                    onDelete={this.onDelete}
                    onSubmit={this.onSubmit}
                    editData={this.parseNotificationToEditData(notification)}
                    hideSaveButton={!canSave}
                />
            </CenterContainer>
        )
    }
}

export default EditNotificationPage;