import * as React from 'react';
import { observer } from 'mobx-react';
import { navigationStore } from 'stores/NavigationStore';
import { Parse } from 'helpers/Parse';
import GlobalContainer from 'components/GlobalContainer';

@observer class DashboardPage extends React.Component<any> {

    constructor(props: any) {
        super(props);

        if (!Parse.User.current()) {
            navigationStore.replace('/login');
        }
    }

    render() {
        const { children } = this.props;

        return (
            <GlobalContainer>
                {children}
            </GlobalContainer>
        )
    }
}

export default DashboardPage;