import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>,
    disabled?: boolean
}

@observer class SelectNotificationIcon extends React.Component<Props> {

    componentDidMount() {
        // audienceStore.getAudiences();
    }

    render() {
        const { fieldState, disabled } = this.props;
        const data = [{"name": "Kontakt",       "value" : "notification_contact"}, 
                      {"name": "Dansk",         "value" : "notification_danish"},
                      {"name": "EU Øko",        "value" : "notification_euorganic"},
                      {"name": "Find butik",    "value" : "notification_store"},
                      {"name": "Fisk",          "value" : "notification_fish"},
                      {"name": "Frost",         "value" : "notification_frozen"},
                      {"name": "Halal",         "value" : "notification_halal"},
                      {"name": "Hjem",          "value" : "notification_home"},
                      {"name": "Indkøbsliste",  "value" : "notification_shoppinglist"},
                      {"name": "Inspiration",   "value" : "notification_inspiration"},
                      {"name": "Nøglehul",      "value" : "notification_keylock"},
                      {"name": "Kølevare",      "value" : "notification_cool"},
                      {"name": "Kundekort",     "value" : "notification_membercard"},
                      {"name": "Mere",          "value" : "notification_more"},
                      {"name": "Nyheder",       "value" : "notification_news"},
                      {"name": "Øko",           "value" : "notification_organic"},
                      {"name": "Profil",        "value" : "notification_profile"},
                      {"name": "Tilbud",        "value" : "notification_product_offers"},
                      {"name": "DGFS Besked ",  "value" : "notification_info"}
                      ];

        const options = data.map((d) => ({
            value: d.value, 
            label: d.name
        }))

        return (
            <Dropdown
                disabled={disabled}
                label='Notifikaion Ikon'
                placeholder='- Vælg'
                fieldState={fieldState}
                options={_.orderBy(options, 'label')}
            />
        )
    }
}

export default SelectNotificationIcon;