import { action } from "mobx";
import { navigationStore } from "./NavigationStore";
import LoadPromise from "helpers/LoadPromise";
import { Parse } from 'helpers/Parse';

export class AuthStore {

    @action
    async login(username: string, password: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.User.logIn(username, password);
                const user = Parse.User.current() as Parse.Object;
                if (user.get('user_type') === 'sys-admin') {
                    navigationStore.push('/');
                    resolve();
                }
                else {
                    reject('Du har ikke de nødvendige rettigheder, til at logge ind')
                }

            }
            catch (err) {
                if (err.code === 101) {
                    reject('Ugyldigt brugernavn eller password');
                }
                else reject(err.error);
            }
        })
    }

    @action
    async logout() {
        await Parse.User.logOut();
        navigationStore.push('/login');
    }

}

export const authStore = new AuthStore();