import React from 'react';
import { observer } from 'mobx-react';
import { Router, Switch, Route } from 'react-router-dom';
import { navigationStore } from 'stores/NavigationStore';
import LoginPage from 'components/LoginPage';
import DashboardPage from 'components/DashboardPage';
import NotificationsPage from 'components/NotificationsPage';
import Toast from 'components/Toast';
import CreateNotificationPage from 'components/CreateNotificationPage';
import EditNotificationPage from 'components/EditNotificationPage';
import NoMatchPage from 'components/NoMatchPage';

@observer class App extends React.Component {

    render() {
        return (
            <Router history={navigationStore.history}>
                <Toast />
                <Switch>
                    <Route exact path='/login' component={LoginPage} />

                    <DashboardPage>
                        <Switch>
                            <Route exact path='/' component={NotificationsPage} />
                            <Route exact path='/notifications/create' component={CreateNotificationPage} />
                            <Route exact path='/notifications/:id' component={EditNotificationPage} />
                            <Route component={NoMatchPage} />
                        </Switch>
                    </DashboardPage>
                </Switch>
            </Router>
        )
    }

}

export default App;