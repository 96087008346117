import * as React from 'react';
import { observer } from 'mobx-react';
import CenterContainer from 'components/CenterContainer';
import NotificationForm from 'components/NotificationForm';
import { notificationStore } from 'stores/NotificationStore';
import _ from 'lodash';
import { NotificationFormDataInterface } from 'components/NotificationForm';
import { navigationStore } from 'stores/NavigationStore';

@observer class CreateNotificationPage extends React.Component<any> {

    onSubmit = async (data: NotificationFormDataInterface) => {
        await notificationStore.create(data);
        navigationStore.push('/');
    }

    render() {
        return (
            <CenterContainer title='Opret Ny Notifikation'>
                <NotificationForm 
                    onSubmit={this.onSubmit} 
                />
            </CenterContainer>
        )
    }
}

export default CreateNotificationPage;