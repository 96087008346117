import * as React from 'react';
import { observer } from 'mobx-react';
import Logo from 'components/Logo';

@observer class Header extends React.Component {

    render() {
        const { children } = this.props;

        return (
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                <div style={{ flex: '1' }}>
                    <Logo height='50' />
                </div>
                <div style={{ flex: '1', textAlign: 'right' }}>
                    {children}
                </div>
            </div>
        )
    }
}

export default Header;