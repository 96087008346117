import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState } from 'formstate';
import { toastStore } from 'stores/ToastStore';

interface Props {
    children: React.ReactNode,
    formState: FormState<any>,
    onValidSubmit: Function,
    onReset?: Function
}

interface State {
    isSubmitting: boolean
}

@observer class Form extends React.Component<Props, State> {

    _isMounted: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            isSubmitting: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSubmitting = (isSubmitting: boolean) => {

        if (!this._isMounted) {
            return;
        }

        // Prevent another submit of the same form for 3 seconds.
        // This is a shallow way of hopefully preventing double submits.
        if (isSubmitting) {
            this.setState({ isSubmitting: true });
            setTimeout(() => {
                if (this._isMounted) {
                    this.setState({ isSubmitting: false })
                }
            }, 3000)
        }
        else {
            this.setState({ isSubmitting: false });
        }
    }

    onReset = async (ev: React.FormEvent) => {
        ev.preventDefault();

        const { onReset, formState } = this.props;
        formState.reset();

        if (onReset) {
            onReset();
        }
    }

    onSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();
        const { formState, onValidSubmit } = this.props;
        const { isSubmitting } = this.state;

        const res = await formState.validate();

        if (res.hasError) {
            toastStore.addError('Et eller flere felter er ikke udfyldt korrekt');
            this.setSubmitting(false);
            return;
        }

        if (!isSubmitting) {
            this.setSubmitting(true);
            onValidSubmit();
        }
    }

    render() {
        const { children } = this.props;

        return (
            <form onSubmit={this.onSubmit} onReset={this.onReset}>
                {children}
            </form>
        );
    }

}

export default Form;