import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

import css from './style.module.css';
import Label from '../Form/Label';

interface Props {
    fieldState: FieldState<any>,
    placeholder?: string,
    type?: 'text' | 'textarea' | 'number' | 'password',
    style?: React.CSSProperties
    label?: string,
    disabled?: boolean
}

@observer class Input extends React.Component<Props> {

    render() {
        const { fieldState, placeholder, type, style, label, disabled } = this.props;

        return (
            <div style={style}>

                {
                    label &&
                    <Label label={label} errorMessage={fieldState.error} />
                }

                {
                    type === 'textarea' &&
                    <textarea
                        placeholder={placeholder}
                        className={`${css.input} ${css.textarea}`}
                        value={fieldState.value ?? ''}
                        rows={10}
                        disabled={disabled}
                        onChange={(e) => fieldState.onChange(e.target.value)}
                    />
                }

                {
                    type !== 'textarea' &&
                    <input
                        placeholder={placeholder}
                        className={css.input}
                        type={type ?? 'text'}
                        value={fieldState.value ?? ''}
                        onChange={(e) => fieldState.onChange(e.target.value)}
                        disabled={disabled}
                    />
                }

            </div>
        )
    }
}

export default Input;