import * as React from 'react';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';
import { notificationStore } from 'stores/NotificationStore';
import { Table, Button } from 'rsuite';
import { Parse } from 'helpers/Parse';
import { navigationStore } from 'stores/NavigationStore';
import Header from 'components/Header';
import { audienceStore } from 'stores/AudienceStore';
import { questionStore } from 'stores/QuestionStore';
import _ from 'lodash';
import { loadingStore } from 'stores/LoadingStore';
import moment from 'moment';

const { Column, HeaderCell, Cell } = Table;

interface State {
    getAllClicked: boolean
}

@observer class NotificationsPage extends React.Component<State> {

    NOTIFICATIONS_DEFAULT = 20;
    NOTIFICATIONS_MAX = 9999;

    state = { getAllClicked: false };

    componentDidMount() {

        if (!Parse.User.current()) {
            navigationStore.replace('/login');
        }
        notificationStore.getAll(this.NOTIFICATIONS_DEFAULT);
        audienceStore.getAudiences();
        questionStore.getQuestions();
    }

    onRowClicked = (rowData: Parse.Object) => {
        navigationStore.push(`/notifications/${rowData.id}`);
    }

    getAllNotifications = () => {
        notificationStore.getAll(this.NOTIFICATIONS_MAX);
        this.setState({ getAllClicked: true });
    }

    render() {
        const { getAllClicked } = this.state;
        const data = notificationStore.notifications;
        const audiences = audienceStore.audiences;
        const isLoading = loadingStore.isLoading;
        if (!isLoading && data.length === 0) return null;

        return (
            <div>
                <Header>
                    <Button color='red' onClick={() => navigationStore.push('/notifications/create')}>Opret Ny Notifikation</Button>

                    <Button
                        style={{ marginLeft: '20px' }}
                        onClick={() => authStore.logout()}>
                        Log ud
                    </Button>
                </Header>

                <Table
                    data={data}
                    rowKey='id'
                    rowHeight={50}
                    onRowClick={this.onRowClicked}
                    loading={isLoading}
                    renderEmpty={() => !isLoading ? <div className='rs-table-body-info'>Ingen notifications</div> : null}
                    renderLoading={() =>
                        <div className='rs-table-loader-wrapper'>
                            <div className='rs-table-loader'>
                                <i className='rs-table-loader-icon' />
                                <span className='rs-table-loader-text'>Henter notifications...</span>
                            </div>
                        </div>
                    }
                    autoHeight>

                    <Column flexGrow={1}>
                        <HeaderCell>Titel</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                return rowData.get('title')
                            }}
                        </Cell>
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Besked</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                return rowData.get('message')
                            }}
                        </Cell>
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Modtagere</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                const audienceId = rowData.get('audienceId');
                                const audience = _.find(audiences, { id: audienceId });
                                return audience?.get('name') ?? null;
                            }}
                        </Cell>
                    </Column>

                     <Column flexGrow={1}>
                        <HeaderCell>Spørgsmål</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                const question = rowData.get('question');
                                return question?.get('question') ?? "-";
                            }}
                        </Cell>
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Sendes</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                const dateStr = rowData.get('schedule_time').toString();
                                if (dateStr) return moment(dateStr).format('DD/MM - YYYY HH:mm')
                                else return null;
                            }}
                        </Cell>
                    </Column>

                    <Column width={80}>
                        <HeaderCell>Sendt</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                return rowData.get('is_sent') ? 'Ja' : 'Nej'
                            }}
                        </Cell>
                    </Column>

                   <Column flexGrow={1}>
                        <HeaderCell>Status</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                return rowData.get('status_message') ?? "afventer"
                            }}
                        </Cell>
                    </Column>

                    <Column flexGrow={1}>
                        <HeaderCell>Push</HeaderCell>
                        <Cell>
                            {(rowData: Parse.Object, rowIndex: number) => {
                                return rowData.get('no_push') === true ? "Uden Push" : "Med Push"
                            }}
                        </Cell>
                    </Column>

                </Table>

                {
                    (!isLoading && data.length > 0 && !getAllClicked) &&
                    <div style={{ textAlign: 'center', margin: '50px 0' }}>
                        <Button onClick={this.getAllNotifications}>Vis alle</Button>
                    </div>
                }

            </div>
        )
    }
}

export default NotificationsPage;